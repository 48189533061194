jQuery(function($){
  $('.video-slider__slider').each(function(){
    var swiper = new Swiper('.video-slider__slider', {
      centeredSlides: true,
      slidesPerView: 'auto',
      speed: 200,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 60
      },
      autoHeight: false,
      spaceBetween: 40
    });
  });
  if ($('.video-slider__slider').length){
    $('.video-slider__slide').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube-nocookie.com/embed/%id%?autoplay=1'
          },
        },
        srcAction: 'iframe_src',
      }
    });
  }
});
