jQuery(function($){
  $('.js-map-filter').on('click', function(){
    var filter = $(this).data('filter');
    $('.map__map svg').removeClass();
    if (!$(this).hasClass('m--active')){
      $('.js-map-filter').removeClass('m--active');
      $('.map__map svg').addClass('m--' + filter);
      $(this).addClass('m--active');
    }
    else {
      $(this).removeClass('m--active');
    }
  });
});
